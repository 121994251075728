const facebookSVG = ({color, size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Facebook-1--Streamline-Core"
                height={size} width={size}>
            <g id="facebook-1--media-facebook-social">
                <path id="Vector" stroke={color} strokeLinecap="round" strokeLinejoin="round"
                      d="M0.5 12.5v-11c0 -0.26522 0.105357 -0.51957 0.292893 -0.707107C0.98043 0.605357 1.23478 0.5 1.5 0.5h11c0.2652 0 0.5196 0.105357 0.7071 0.292893 0.1875 0.187537 0.2929 0.441887 0.2929 0.707107v11c0 0.2652 -0.1054 0.5196 -0.2929 0.7071s-0.4419 0.2929 -0.7071 0.2929h-3V8.76h0.71c0.1618 0 0.3169 -0.06427 0.4313 -0.17866 0.1144 -0.1144 0.1787 -0.26956 0.1787 -0.43134v-0.77c0 -0.08011 -0.0158 -0.15943 -0.0464 -0.23344 -0.0307 -0.07401 -0.0756 -0.14125 -0.1323 -0.1979 -0.0566 -0.05664 -0.1239 -0.10157 -0.1979 -0.13223 -0.074 -0.03065 -0.1533 -0.04643 -0.2334 -0.04643h-0.67v-0.94c0 -0.84 0.38 -0.84 0.76 -0.84h0.49c0.0805 0.00332 0.1607 -0.01109 0.235 -0.04219 0.0743 -0.0311 0.1409 -0.07815 0.195 -0.13781 0.0581 -0.05532 0.1041 -0.12211 0.1351 -0.19612 0.031 -0.07402 0.0463 -0.15365 0.0449 -0.23388v-0.74c0.0027 -0.08143 -0.0107 -0.16259 -0.0395 -0.23883 -0.0287 -0.07625 -0.0721 -0.14609 -0.1279 -0.20553 -0.0557 -0.05943 -0.1226 -0.10731 -0.1968 -0.14088S10.8814 3.00258 10.8 3H9.65c-0.33555 -0.01262 -0.66984 0.04776 -0.97979 0.17696 -0.30994 0.12919 -0.58814 0.32413 -0.81538 0.57134 -0.22725 0.24722 -0.39812 0.54081 -0.50082 0.86051 -0.1027 0.3197 -0.13477 0.65788 -0.09401 0.99119v1.17h-0.64c-0.08094 -0.00133 -0.16134 0.01347 -0.23651 0.04353 -0.07517 0.03006 -0.1436 0.07478 -0.2013 0.13156 -0.05771 0.05678 -0.10354 0.12447 -0.13482 0.19914C6.01609 7.2189 5.99999 7.29905 6 7.38v0.77c-0.00001 0.08095 0.01609 0.1611 0.04737 0.23577 0.03128 0.07467 0.07711 0.14236 0.13482 0.19914 0.0577 0.05678 0.12613 0.1015 0.2013 0.13156 0.07517 0.03006 0.15557 0.04486 0.23651 0.04353h0.64v4.74H1.5c-0.26522 0 -0.51957 -0.1054 -0.707107 -0.2929C0.605357 13.0196 0.5 12.7652 0.5 12.5Z"
                      strokeWidth="1"></path>
            </g>
        </svg>
    );
}

export default facebookSVG;